var config = {

    demo:false, // Set the Demo Version

    //SEO Configurations
    appName: "ZamSku Quiz",
    metaDescription: "ZamSku Quiz is a Web Quiz Application",
    metaKeyWords: "ZamSku Quiz,Quiz,Questions,Answers,Online Quiz",

    //API Configurations
    apiAccessKey: 8525,
    apiUrl: "https://distinctionpack.net/elitequiz/api/",
    // apiUrl: "http://flutterquiz.thewrteam.in/api/",

    //Language Configurations
    // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
    supportedLanguages:["en","hi","ur"],
    defaultLanguage:"en",
    /**
     * If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.
     */


    //Quiz Configurations
    questionTimerSeconds: 15,
    levelWinCheckPoint: 30,         // Above 30% is required to Clear the Quiz Level
    maxWinningCoins:4,              // This will give user the coins if user will clear the level
    deductReviewAnswerCoins: 10,    // 10 coins will be deducted if user Review the Answer
    addCorrectAnswerScore:2,        // This will increase the user score after each correct answer
    deductIncorrectAnswerScore:1,   // This will deduct the points if user will give wrong answer
    deductLifeLineCoins:1,          // Deduct Coins when using Life Line


    DefaultCountrySelectedInMobile: "zm",   //Default Country Selected in Mobile Login Screen

    //Firebase Configurations
    apiKey: "AIzaSyDlpb5fcJh9qqrjUMwTfk4m2SO0GAav0WQ",
    authDomain: "zamschool-quiz.firebaseapp.com",
    projectId: "zamschool-quiz",
    storageBucket: "zamschool-quiz.appspot.com",
    messagingSenderId: "1086470295180",
    appId: "1:1086470295180:web:22d81fb270d34fff3135a9",
    measurementId: "G-0J6X8LNDVH"
};

export default config;